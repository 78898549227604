import { useContext, useState, useEffect } from "react";
import { DashboardContext } from "../context/DashboardContext";

import badge_icons from "../constants/badges_img";
import { createPortal } from "react-dom";

import { BadgesProps } from "../types";

const sentiment_empty = require("../img/badges/sentiment_empty.png");
const engagement_empty = require("../img/badges/engagement_empty.png");
const caring_empty = require("../img/badges/caring_empty.png");

const Badges: React.FC<BadgesProps> = ({badges}) => {

    const {fromDate, toDate} = useContext(DashboardContext);
    const [filteredBadges, setFilteredBadges] = useState<any[]>(badges);

    const [detailBadge, setDetailBadge] = useState<any>(null);

    function GetBadgeImage(props:any):any{
        var s = props.badge.streak-1;
        var style="w-[224px] mx-auto relative";
        if(s>2){
            style = "w-[324px] mx-auto relative"
        }
        if(s>4){
            s = 4;
        }
        //default value
        var src = badge_icons["engagement_individual"][0]
        if(props.badge.badge_type === 'all_rounder'){
            if(props.badge.badge_level==="gold"){
                src = badge_icons[props.badge.badge_type][1]
            }
            else{
                src = badge_icons[props.badge.badge_type][0]
            }
        }
        else{
            src = badge_icons[props.badge.badge_type][s];
        }
        
       
        return(<img className={style} src={src} alt="" />)
    }

    useEffect(() => {
        //filter badges based on the dates via the awarded_date property
        if(fromDate !== "" && toDate !== ""){
            const filteredBadges_:any[] = badges.filter((badge)=>{
                const awarded_date = new Date(badge.awarded_date);
                return awarded_date >= new Date(fromDate) && awarded_date <= new Date(toDate);});

            setFilteredBadges(filteredBadges_);
        }else{
            setFilteredBadges(badges);
        }
    }, [fromDate, toDate]);

    if(filteredBadges.length === 0){
        return (
            <div className="w-full rounded-xl bg-white mt-6 p-8" style={{maxWidth:424, minHeight:80}}>
                <h4 className="text-left font-bold text-dark">Achievements over time</h4>
                <div className="flex flex-row flex-wrap justify-between mt-6">
                    
                    <div className="w-[116px] text-center">
                        <img src={engagement_empty} alt="" />
                        <p className="text-gray-400 font-medium">Engagement</p>
                    </div>

                    <div className="w-[116px] text-center">
                        <img src={caring_empty} alt="" />
                        <p className="text-gray-400 font-medium">Caring</p>
                    </div>

                    <div className="w-[116px] text-center">
                        <img src={sentiment_empty} alt="" />
                        <p className="text-gray-400 font-medium">Sentiment</p>
                    </div>

                </div>

                <p className="font-bold my-2 mt-6 text-center text-lg">No badges yet!</p>
                <p className="text-center">Keep engaging with your team and completing tasks to earn your first badge!</p>
            </div>
        )
    }

    return (
    <div className="w-full rounded-xl bg-white mt-6 p-8" style={{maxWidth:424, minHeight:80}}>
        <h4 className="text-left font-bold text-dark">Achievements over time</h4>
        <div className="flex flex-row flex-wrap">

        {filteredBadges.length === 0 && <p>No badges to show</p>}

        {filteredBadges.map((badge, index) => {
                return(
                    <div className="w-[30%] mr-[3%] mt-4 cursor-pointer" onClick={()=>{ setDetailBadge(badge); }}>
                    <GetBadgeImage badge={badge} />
                    <p className="text-center text-dark">{badge.name}</p>
                </div>
                )
            })
        }
        </div>

        {/* detailBadge && createPortal(
        <div className="popup-container">
            <div className="popup-content">
                <span className="close cursor-pointer" onClick={()=>{setDetailBadge(null)}}>&times;</span>
                <GetBadgeImage badge={detailBadge} />
                <h2 className="font-bold text-dark text-3xl">{detailBadge.name}</h2>
                <p>{detailBadge.description}</p>
            </div>
        </div>, document.body) */}
        

    </div>)
}

export default Badges;
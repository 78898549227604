import { useEffect, useState } from "react";

import Ring from "./rint";
import MiniLineChart from "./miniLineChart";
import { sentiment_rgb, caring_rgb, engagement_rgb } from "../config";

import InsightManager from "../manager/insightManager";


const engagement_icon = require('../img/engagement_icon.png');
const caring_icon = require('../img/caring_icon.png');
const sentiment_icon = require('../img/sentiment_icon.png');

const arrow_up = require('../img/arrow-up-green.png');

interface BestMetricsProps {
    insights: any;
}

const BestMetrics: React.FC<BestMetricsProps> = ({insights}) => {
    
    const [bestMetric, setBestMetric] = useState<any>(null);

    const data = [10, 50, 30, 90, 70, 110, 80];  // Example data

    const icons: {[key:string]:any} = {
        "engagement_individual": engagement_icon,
        "caring_individual": caring_icon,
        "sentiment_individual": sentiment_icon
    }

    const colors: {[key:string]:any} = {
        "engagement_individual": engagement_rgb,
        "caring_individual": caring_rgb,
        "sentiment_individual": sentiment_rgb
    }

    const attribute: {[key:string]:string} = {
        "engagement_individual": "Engagement",
        "caring_individual": "Caring",
        "sentiment_individual": "Sentiment"
    }

    const subtitle: {[key:string]:string} = {
        "engagement_individual": "Engagement for this period",
        "caring_individual": "Caring for this period",
        "sentiment_individual": "Sentiment for this period"
    }

    const description: {[key:string]:string} = {
        "engagement_individual": "Engagement was your best attribute in this time",
        "caring_individual": "Caring was your best attribute in this time",
        "sentiment_individual": "Sentiment was your best attribute in this time"
    }

    useEffect(()=>{

        const best_improvement = InsightManager.GetBestMetric(insights);
        if(best_improvement.insight){   
            const result = {
                icon: icons[best_improvement.insight.insight_type],
                rgb: colors[best_improvement.insight.insight_type],
                value: best_improvement.final_value,
                improvement: best_improvement.improvement,
                improvement_subtitle: subtitle[best_improvement.insight.insight_type],
                best_attribute: attribute[best_improvement.insight.insight_type],
                best_attribute_subtitle: description[best_improvement.insight.insight_type],
                data : best_improvement.values
            };
            console.log("Best Metric", result);
            setBestMetric(result);
        }
    }, [insights]);

    if(!bestMetric){
        return null;
    }

    return (
    <div className="mt-4 flex flex-col w-full max-w-[424px]">
        
        <div className="w-full bg-white px-4 py-6 rounded-xl fade-in flex flex-row">
            <div className="flex flex-col mr-2">
                <div className="flex flex-row items-end">
                    <b className="text-3xl mr-2">{bestMetric.value}%</b>
                    <b className="text-xl text-green-600">{parseInt(bestMetric.improvement)}%</b>
                    <img className="w-[24px] h-auto mb-1" src={arrow_up} alt="" />
                </div>
                <p className="text-gray-600 whitespace-nowrap text-sm">{bestMetric.improvement_subtitle}</p>
            </div>
            <MiniLineChart className={"mx-auto"} values={bestMetric.data} width={184} height={47} strokeColor={bestMetric.rgb} strokeWidth={6}/>
        </div>

        {/**
        <div className="w-full bg-white px-2 py-4 rounded-xl fade-in flex flex-row mt-4 items-center">
            <div className="w-[70px] h-[70px] relative">
                <Ring 
                color={bestMetric.rgb}
                progress={bestMetric.value}
                strokeWidth={1.5}
                size={40}
                ></Ring>
                <img src={bestMetric.icon} className="w-[28px] h-auto absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" alt="" />
            </div>
            <div className="flex flex-col ml-2">
                <b className="text-xl">{bestMetric.best_attribute}</b>
                <p className="text-gray-600 text-sm mt-[-4px]">{bestMetric.best_attribute_subtitle}</p>
            </div>
        </div>
        */}
    </div>)
};

export default BestMetrics;
import React from "react";
import { useContext } from "react";

import { DashboardContext } from "../context/DashboardContext";

import Ring from "./rint";

import { engagement_rgb } from "../config";

const filter_icon = require("../img/filter-icon.png");

interface SprintHistoryProps {
    sprints: any[];
}

const SprintHistory = ({sprints}:SprintHistoryProps) =>{


    const {setShowFilter, showFilter, selectedSprint, setSelectedSprint, setDates} = useContext(DashboardContext);

    //split the sprints with the last 6 elements
    sprints = sprints.slice(-6);
    
    return (
        <div className="flex flex-row justify-end my-4 " >
            {sprints.map((sprint:any, index: number)=>{
                return <div 
                onClick={()=>{
                    setSelectedSprint(sprint); 
                    setShowFilter(false); 
                    setDates(sprint.start_date, sprint.end_date);
                 }}
                className="w-[36px] relative cursor-pointer mr-6" 
                style={{opacity: (selectedSprint===sprint) ?1:0.25}}> 
                <Ring 
                color= {engagement_rgb}
                progress={sprint.overal?sprint.overal:0}
                strokeWidth={6}
                size={40}
                scale={1.4}
                ></Ring>
                
                {/** extract the number from the sprint name to show it */}
                {sprint.name && <p className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">{sprint.name.match(/\d+/)[0]}</p>}
                
                </div>

            })}
            <button onClick={()=>{ setShowFilter(!showFilter) }} className="w-[48px] h-[48px] rounded-full bg-purple flex justify-center items-center">
                <img src={filter_icon} className="w-[18px] h-auto"  alt="" />
            </button>
        </div>
    )
}

export default SprintHistory;
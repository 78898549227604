import {  useState, useEffect, useContext } from "react";

import { DashboardContext } from "../context/DashboardContext";

import { engagement_rgb, sentiment_rgb, caring_rgb } from "../config";

import  Line from "./Line";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, CoreChartOptions, elements } from "chart.js";

import InsightManager from "../manager/insightManager";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const dummy_data = {
  labels: ["a","b","c","d","e"],
  datasets:[
    {
      data: [80,87,98,70,90],
      label: "Engagement",
      borderColor:engagement_rgb,
    },
    {
      data: [60,47,48,55,83],
      label: "Caring",
      borderColor:caring_rgb,
    },
    {
      data: [20,25,38,43,78],
      label: "Sentiment",
      borderColor:sentiment_rgb,
    }
  ]
}

const engagement_icon = require('../img/engagement_icon.png');
const caring_icon = require('../img/caring_icon.png');
const sentiment_icon = require('../img/sentiment_icon.png');

const engagement_icon_w = require('../img/engagement_icon_white.png');
const caring_icon_w = require('../img/caring_icon_white.png');
const sentiment_icon_w = require('../img/sentiment_icon_white.png');


const options:any={
    scales:{
      y: {
        min:0,
        max:100, 
        innerHeight:500
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      line: {
        lineTension: 0.45,
        borderWidth:4
      }, 
      point:{
        radius:1,
        borderWidth:1
      }
  }
}

interface LineChartProps {
    insights: any;
}

const MakeDataSet = (values:any, label:string, color:string):any => {
    return {
        label: label,
        data: values,
        fill: false,
        borderColor: color,
        tension: 0.1
    }
};

// Function to replace nulls with the previous valid value
const fillNulls = (array:any[]) => {
  for (let i = 1; i < array.length; i++) {
      if (array[i] === null) {
          array[i] = array[i - 1];
      }
  }
  for (let i = array.length - 2; i >= 0; i--) {
      if (array[i] === null) {
          array[i] = array[i + 1];
      }
  }
  return array;
};



const LineChart: React.FC<LineChartProps> = ({insights}) => {




    const {filters, setFilters, fromDate, toDate} = useContext(DashboardContext);

  
    const [data, setData] = useState<any>(null);
    //for the chart to support multiple lines we need to filtrate data by different types of insights. 
    //For now we will only support one line.
    //var data = InsightManager.GetLabelsAndValues(insights, "engagement_individual");

    useEffect(()=>{
      if(insights.length>0){
        SetupData();
      }
    }, [filters]);
    
    useEffect(()=>{
      if(insights.length > 0){
        SetupData();
      }
    }, []);


    function SetupData(){
        const engagement_metrics:any = InsightManager.GetLabelsAndValues(insights, "engagement_individual", 10) || [];
        const caring_metrics:any = InsightManager.GetLabelsAndValues(insights, "caring_individual", 10) || [];
        const sentiment_metrics:any = InsightManager.GetLabelsAndValues(insights, "sentiment_individual", 10) || [];
    
        //create the labels from the keys of the metrics, without repearing values and organizing them in order.
        //This will be the x axis of the chart.
        const d = []
        if(filters.length === 0){
          d.push(...Object.keys(engagement_metrics));
          d.push(...Object.keys(caring_metrics));
          d.push(...Object.keys(sentiment_metrics));
        }
        else{
          if(filters.includes("engagement")){ d.push(...Object.keys(engagement_metrics)); }
          if(filters.includes("caring")){ d.push(...Object.keys(caring_metrics));}
          if(filters.includes("sentiment")){ d.push(...Object.keys(sentiment_metrics));}
        }
        
        // Step 1: Create a labels array containing all unique keys
        const allKeys = new Set(d);
        const labels = Array.from(allKeys).sort();
    
        // Step 2: Create arrays for each object based on the labels
        const engagementData = labels.map(key => engagement_metrics[key] || null);
        const caringData = labels.map(key => caring_metrics[key] || null);
        const sentimentData = labels.map(key => sentiment_metrics[key] || null);
    
        fillNulls(engagementData);
        fillNulls(caringData);
        fillNulls(sentimentData);

        const datasets = [];
        if(filters.length === 0 || filters.includes("engagement")){
          datasets.push(MakeDataSet(engagementData, "Engagement",  `rgba(${engagement_rgb}, 1)`));
        }
        if(filters.length === 0 || filters.includes("caring")){
          datasets.push(MakeDataSet(caringData, "Caring",  `rgba(${caring_rgb}, 1)`));
        }
        if(filters.length === 0 || filters.includes("sentiment")){
          datasets.push(MakeDataSet(sentimentData, "Sentiment",  `rgba(${sentiment_rgb}, 1)`));
        }

        setData({
          labels: labels,
          datasets: datasets
        });
    }

    if(insights.length === 0){
      return null;
    }

    const ToggleFilter = (type:string) => {
      //add or remove the filter from the filter array
      if(filters.includes(type)){
        setFilters(filters.filter((f:string) => f !== type));
      }
      else{
        setFilters([...filters, type]);
      }
    }

    interface FilterButtonProps{
      icon: any, 
      icon_selected:any,
      filter:string,
      bg_color:string,
      border_color:string,
      label:string
    }

    function FilterButton({icon, filter, bg_color, border_color, icon_selected, label}:FilterButtonProps){

      const enabled =  filters.includes(filter);

      const style:any = {borderBottomWidth:12, borderColor:border_color}
      if(enabled){
        style.backgroundColor = bg_color;
      }

      return(
        <button  onClick={()=>{ ToggleFilter(filter) }} >
          <div className="bg-gray-100 mx-2 p-4 rounded-xl" style={style} >
            <img src={enabled?icon_selected:icon} alt="" style={{height:46, width:"auto" }}/>
          </div>
          <p className="font-medium text-sm text-gray-700 mt-2">{label}</p>
        </button>
      )
    }

    function GetPositionForMarker(){

      const selected_label = toDate.slice(5);
      let pos = 1;
      for (let i = 0; i < data.labels.length; i++) {
        if(data.labels[i] === selected_label){
          pos = i;
        }
      }

      //map a number between 1 and the lenght of labels
      //to a number between 15 and 339
      const scale = 323/data.labels.length;
      return 33 + (pos*scale);

    }


    return(
        <div className="bg-white rounded-lg mt-4 p-6" style={{width:424}}>
            <h4 className="font-bold text-dark mb-4">Sprint performance over time</h4>
            {data &&
                <Line 
                  chartName="SPRINT"
                  width={380}
                  height={223}
                  data={data}
                />
            }
        </div>
    )
}

export default LineChart;